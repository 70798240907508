@font-face {
  font-family: 'Titillium Web';
  src: url('TitilliumWeb-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Titillium Web';
  src: url('TitilliumWeb-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Titillium Web';
  src: url('TitilliumWeb-Light.ttf') format('truetype');
  font-weight: lighter;
  font-style: normal;
  font-display: fallback;
}

/* VARIABLES FOR FONTS */

:root {
  --font-family-default: "Titillium Web", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --font-family-mono: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* BASIC CSS RESET */

html {
  font-size: 16px;
}

body {
  margin: 0;
  width: 100vw;
  min-height: 100vh;
  background: #FFFFFF;
  color: #333;
  font-family: var(--font-family-default);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  line-height: 1.4rem;
}

code {
  font-family: var(--font-family-mono);
}

.update-notification {
  background-color: #222;
  color: #fff;
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 10px;
  font-size: 0.9rem;
  padding: 16px 20px 20px;
  border-radius: 4px;
}
.update-notification a {
  color: #08B;
  font-weight: bold;
  text-transform: uppercase;
}
.update-notification a:hover {
  color: #069;
}
.update-notification button {
  color: white;
  background: none;
  border: none;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  font-size: 1.8rem;
  line-height: 30px;
  vertical-align: -4px;
  cursor: pointer;
  opacity: 0.6;
  display: inline-block;
  outline: none;
}
.update-notification button:hover {
  opacity: 1;
}

@media screen and (max-width: 599px) {
  .update-notification {
    bottom: 70px;
    position: fixed;
  }
}